.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.modal_container .modal_controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.modal_container .modal_controls button {
  cursor: pointer;
  margin-top: 20px;
  width: 20%;
  height: 3rem;
  outline: none;
  font-size: 1.3rem;
  border: none;
  margin-right: 3rem;
  margin-left: 3rem;
}

.modal_container .form_input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
}

.modal_container .form_input_container .form_input label {
  flex: 1;
  padding-left: 5%;
  font-size: 1.5rem;
}

.modal_container .form_input_container .form_input {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.modal_container .form_input_container .form_input input {
  flex: 5;
  margin-right: 5%;
  font-size: 1.5rem;
  text-decoration: none;
  border: 1px solid gray;
}

.modal_container .form_input_container .form_input input:invalid {
  border: red solid 3px;
}

.modal_container .form_input_container .form_input input:focus {
  outline: 0;
}

.modal_container .form_input_container .form_input select {
  flex: 5;
  margin-right: 5%;
  font-size: 1.5rem;
  text-decoration: none;
}
