.page {
  width: 100vw;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: row;
}

.block_left {
  position: fixed;
  left: 0;
  right: 0;
  top: 78px;
  height: inherit;
  overflow-y: hidden;
  flex: 1;
  width: 20vw;
  border-right: 3px solid #e5e5e5;
}

.block_left:hover {
  overflow-y: scroll;
}

.block_right {
  position: fixed;
  margin-left: 20vw;
  width: calc(100% - 20vw);
  left: 0;
  right: 0;
  top: 78px;
  height: inherit;
  overflow-y: scroll;
  flex: 4;
}

.block_right .stats_table {
  width: 100%;
  border-collapse: collapse;
}

.block_right .stats_table th {
  border: 1px solid gray;
  padding: 0;
}

.controls {
  display: flex;
  flex-direction: row;
}

.teacher_item {
  width: 100%;
}

.item_header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.item_data table {
  border-top: none;
  border: 1px solid #d6d8d8;
  border-collapse: collapse;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  line-height: 50px;
  empty-cells: show;
}

.item_data th {
  line-height: 6vh;
  empty-cells: show;
  border-top: none;
  border: 1px solid #d6d8d8;
  border-collapse: collapse;
  background-color: #eff0f0;
  cursor: default;
}

.item_data td {
  cursor: default;
}

.block_left ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.block_left li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  padding: 1rem;
  border-bottom: 1px solid #e6eaea;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block_left .active {
  background-color: #e6eaea;
}

.block_left li:hover {
  background-color: #e6eaea;
}

.block_left li p {
  color: #191919;
  line-height: 2;
  margin: 0rem;
  margin-block: 0rem;
}
