.list_wrapper {
  width: 100%;
  align-items: center;
}

.list_wrapper ul {
  margin: 0;
  padding: 0;
}

h2 {
  font-family: 'IBM Plex Serif', serif;
  font-weight: lighter;
  text-transform: uppercase;
}

h1 {
  font-family: 'IBM Plex Serif', serif;
  font-weight: lighter;
  text-transform: uppercase;
}

.change_item {
  display: flex;
  width: 100%;
  flex-direction: column;
  direction: ltr;
  background-color: white;
}

.change_item .text_panel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  z-index: 1;
}

.change_item .text_panel.visible {
  box-shadow: 0px 2px 6px grey;
}

.change_item .edit_panel {
  background-color: white;
  display: none;
  width: 100%;
}

.change_item .edit_panel.visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.change_item .edit_panel .form_input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.change_item .edit_panel .form_input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.change_item .edit_panel .form_controls {
  display: flex;
  flex-direction: row;
}

.change_item .edit_panel .form_controls div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0rem 3rem;
  padding: 0rem 1rem;
  cursor: pointer;
}

.change_item .edit_panel .form_controls div:hover {
  background-color: #f6f6f6;
}

.change_item .edit_panel .form_controls div p {
  margin-right: 1rem;
}

.change_item .arrow_icon_container.visible {
  visibility: visible;
}

.change_item .edit_panel input {
  margin: 1rem 1rem;
}

.change_item .edit_panel input:invalid {
  border: 3px solid red;
}

.change_item .arrow_icon_container {
  visibility: hidden;
  margin-right: 1rem;
  border-radius: 50%;
  align-items: center;
}

.change_item:hover .arrow_icon_container {
  visibility: visible;
  flex: 1;
}

.change_item:hover {
  background-color: #f6f6f6;
}

.change_item .text_active {
  color: black;
}

.change_item .text {
  color: rgb(175, 175, 175);
}

.change_item.clicked {
  background-color: #f6f6f6;
}

.changes_block {
  direction: ltr;
  display: none;
  position: fixed;
  overflow-y: scroll;
  height: inherit;
  width: 33%;
}

.changes_block.left {
  left: 0;
  width: calc(100% / 3);
}

.changes_block.right {
  left: calc(100% / 3 * 2);
  width: calc(100% / 3);
}
.changes_block.center {
  left: calc(100% / 3);
  width: calc(100% / 3);
}

.changes_block .block_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  height: 40px;
  width: 100%;
  top: 0;
  line-height: 40px;
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid #f6f6f6;
}

.changes_block.visible {
  display: inline-block;
}

.modal {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
  background-color: white;
}

.modal:focus-visible {
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalfile {
  position: absolute;
  top: 40%;
  left: 40%;
  right: 40%;
  bottom: 40%;
  background-color: white;
}

.modalfile:focus-visible {
  outline: none;
}

.class_paragraph {
  text-align: left;
  padding-left: 5%;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
  margin-left: 5%;
  margin-right: 5%;
  color: #818181;
}
