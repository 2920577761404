.filepicker_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.filepicker_container h1 {
  font-size: 1rem;
}

.filepicker_container button {
  border: 0;
  background-color: white;
  cursor: pointer;
  padding: 10px;
}

.filepicker_container button:hover {
  background-color: #eeeeee;
}

.filepicker_container input {
  width: 70%;
  cursor: pointer;
}
