.error_container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.error_code {
  font-size: 20rem;
  color: grey;
  margin: 0;
}

.error_message {
  font-size: 3rem;
  color: grey;
}
